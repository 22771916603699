
let flyoutBtns = document.querySelectorAll(".js-open-flyout"),
    flyoutCloseBtns = document.querySelectorAll(".js-close-flyout"),
    flyoutInner = document.querySelector(".js-flyout-inner"),
    flyoutImageEl = document.querySelector(".js-flyout-img"),
    flyoutTitleEl = document.querySelector(".js-flyout-title"),
    flyoutContentEl = document.querySelector(".js-flyout-content-destination"),
    flyout = document.querySelector(".js-flyout"),
    flyoutImage,
    flyoutTitle,
    flyoutContent;

if (flyoutBtns.length > 0) {
    
    contentFlyouts();
    
}

function contentFlyouts() {

    flyoutBtns.forEach((flyoutBtn) => {
        flyoutBtn.addEventListener("click", function (e) {
            e.preventDefault();

            body.classList.add("stop-scrolling");

            flyoutImage = flyoutBtn.dataset.image;
            flyoutTitle = flyoutBtn.dataset.title;
            flyoutContent = flyoutBtn.querySelector(".js-flyout-source").innerHTML;

            flyoutImageEl.src = flyoutImage;
            flyoutTitleEl.innerHTML = flyoutTitle;
            flyoutContentEl.innerHTML = flyoutContent;

            gsap.to(flyout, 0.3, { autoAlpha: 1 });
            gsap.to(flyoutInner, 0.3, { xPercent: -100 });
        });
    });

    flyoutCloseBtns.forEach((flyoutCloseBtn) => {
        flyoutCloseBtn.addEventListener("click", function () {

            body.classList.remove("stop-scrolling");
            gsap.to(flyout, 0.3, { autoAlpha: 0 });
            gsap.to(flyoutInner, 0.3, { xPercent: 0 });

        });
    });

}