


const stickyElem = document.querySelector(".js-sticky"),
    footer = document.querySelector(".js-footer");
let stickyScroll = 0, stickyPageHeight, stickyScrollOffset = 0;

if (stickyElem) {
    
    stickyPageHeight = document.body.clientHeight - height - footer.offsetHeight;
    imagesLoaded( body, function( instance ) {
        stickyPageHeight = document.body.clientHeight - height - footer.offsetHeight; // footer is 86
    });

    $(window).on('scroll',stickyHeroScroll);

}


function stickyHeroScroll() {
    stickyScroll = $(this).scrollTop();

    stickyScrollOffset = stickyScroll - stickyPageHeight;

    //console.log(stickyScroll + ' / ' + stickyPageHeight);

    if (stickyScrollOffset > 0) {
        stickyElem.style.bottom = stickyScrollOffset + 'px';
    } else {
        stickyElem.style.bottom = 0;
    }

}