// Fade in HTML5 Videos

let videoElems = document.getElementsByClassName("inline-video");

if (videoElems.length > 0) {
    inlineVideo();
}

function inlineVideo() {
    for (let i = 0; i < videoElems.length; i++) {
        videoElems[i].addEventListener("canplay", videoReady);

        if (videoElems[i].readyState > 3) {
            videoElems[i].classList.add("inline-video--ready");
        }

        videoElems[i].addEventListener('pause', function() {
            videoElems[i].play();
        });
    }
}

function videoReady() {
    this.classList.add("inline-video--ready");
}

let videoBtns = document.querySelectorAll(".js-video-play"),
videoOverlay = document.querySelector(".js-video-overlay"),
videoClose = document.querySelector(".js-video-close"),
vimeoEmbed = document.querySelector(".js-vimeo-embed"),
triggerBtns = document.querySelectorAll(".js-warning-btn"),
filmWrapper = document.querySelector(".js-film-wrapper"),
warningOverlay = document.querySelector(".js-warning-overlay"),
warningClose = document.querySelector(".js-warning-close"),
warningPlay = document.querySelector(".js-warning-play"),
warningTitle = document.querySelector(".js-warning-title"),
vimeoPlayer, videoID, vimeoOptions, firstVimeoPlay = true, videoTitle;



if (videoBtns.length > 0) {
    vimeoPlayers();
}

function vimeoPlayers() {

    if (videoBtns) {

    videoBtns.forEach((videoBtn) => {
        videoBtn.addEventListener("click", function () {
            
            body.classList.add("stop-scrolling");
            videoID = this.dataset.id;
        
            if (firstVimeoPlay) {
                vimeoOptions = {
                    id: videoID
                };
                vimeoPlayer = new Vimeo.Player(vimeoEmbed, vimeoOptions);
                firstVimeoPlay = false;
            } 

            vimeoPlayer.loadVideo(videoID).then(function(id) {
                vimeoPlayer.setVolume(1);
                vimeoPlayer.play();
            }).catch(function(error) {
                switch (error.name) {
                    case 'TypeError':
                        // the id was not a number
                        break;
            
                    case 'PasswordError':
                        // the video is password-protected and the viewer needs to enter the
                        // password first
                        break;
            
                    case 'PrivacyError':
                        // the video is password-protected or private
                        break;
            
                    default:
                        // some other error occurred
                        break;
                }
            });

            TweenMax.to(videoOverlay, 0.3, { autoAlpha: 1 });

            if (triggerBtns.length > 0) {
                TweenMax.to(warningOverlay, 0.3, { autoAlpha: 0 });
                filmWrapper.classList.remove('visually-blurred');
            }
        });
    });

    }

    if (videoClose) { 

        videoClose.addEventListener("click", function () {
            body.classList.remove("stop-scrolling");
            vimeoPlayer.pause();
            TweenMax.to(videoOverlay, 0.3, { autoAlpha: 0 });
        });

    }

    if (triggerBtns.length > 0) {

        triggerBtns.forEach((triggerBtn) => {
            triggerBtn.addEventListener("click", function () {

                body.classList.add("stop-scrolling");
                filmWrapper.classList.add('visually-blurred');
                videoID = this.dataset.id;
                videoTitle = this.dataset.title;

                warningPlay.dataset.id = videoID;
                warningTitle.innerHTML = videoTitle;

                TweenMax.to(warningOverlay, 0.3, { autoAlpha: 1 });

            });
        });

    }

    if (warningClose) {

        warningClose.addEventListener("click", function () {
            body.classList.remove("stop-scrolling");
            filmWrapper.classList.remove('visually-blurred');
            TweenMax.to(warningOverlay, 0.3, { autoAlpha: 0 });
        });

    }
}



