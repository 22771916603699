window.onresize = windowResizeEvent;


function windowResizeEvent() {
    if (window.innerWidth != width) {
        //console.log('width has changed');
        if (heroEl) {
            heroSize();
        }
    }

    height = window.innerHeight;
    width = window.innerWidth;
    
    // if (filmHero.length > 0) {
    //     // this needs to take into account the footers height
    //     filmPageHeight = document.body.clientHeight - height - footer.offsetHeight; 
    // }

    if (window.innerWidth || window.innerHeight != height) {
        //console.log('both width &/or height have changed');
       
    }
}
