//mobile form

const mobileFormButton = document.querySelector(".form__mobile-button-wrapper");
const form = document.querySelector(".js-form");
const closeButton = document.querySelector(".close-icon__container");
const formText = document.querySelector(".form__text-wrapper");
const submitButton = document.querySelector(".form__submit");

function openForm() {
    TweenMax.to(form, 0.1, { autoAlpha: 1 });
    body.classList.add("stop-scrolling");
    //form.classList.add('form--mobile');
    closeButton.classList.add("close-icon__container--open");
    formText.classList.add("form__text-wrapper--open");
}

function closeForm() {
    TweenMax.to(form, 0.1, { autoAlpha: 0 });
    body.classList.remove("stop-scrolling");
    //form.classList.remove('form--mobile');
    closeButton.classList.remove("close-icon__container--open");
    formText.classList.remove("form__text-wrapper--open");
}

if (mobileFormButton) {
    mobileFormButton.addEventListener("click", function () {
        openForm();
    });
}

if (closeButton) {
closeButton.addEventListener("click", function () {
    closeForm();
});
}

//window.addEventListener('resize', closeForm);

//EMAIL SIGNUP
const formSubmit = document.querySelector(".js-submit"),
    mailChimpSubmit = document.querySelector(".js-mailchimp-submit"),
    fname = document.querySelector(".js-form-name"),
    email = document.querySelector(".js-form-email"),
    category = document.querySelector(".js-form-category"),
    country = document.querySelector(".js-form-country"),
    formMsg = document.querySelector(".js-form-msg"),
    privacy = document.querySelector(".js-form-privacy"),
    privacyLabel = document.querySelector(".js-form-privacy-label");

let error = false,
    emailVal;

var $errorResponse = $("#errorResponse");

if (formSubmit) {
formSubmit.addEventListener("click", function (e) {
    e.preventDefault();

    error = false;

    if (fname.value == "Name") {
        error = true;
        fname.classList.add("form__input--error");
      //  console.log("Please enter your first name");
    } else {
        fname.classList.remove("form__input--error");
    }

    if ((email.value == "Email") || (validateEmail(email.value) === false) ) {
        error = true;
        email.classList.add("form__input--error");
        //console.log("Please enter your email address");
    } else {
        email.classList.remove("form__input--error");
    }
    
    if (category.value == "") {
        error = true;
        category.classList.add("form__input--error");
       // console.log("Please select a category");
    } else {
        category.classList.remove("form__input--error");
    }

    if (country.value == "") {
        error = true;
        country.classList.add("form__input--error");
       // console.log("Please select a country");
    } else {
        country.classList.remove("form__input--error");
    }

    if (!privacy.checked) {
        error = true;
        //console.log("Please agree to our privacy policy");
        privacyLabel.classList.add("form__checkbox-privacy--error");
    } else {
        privacyLabel.classList.remove("form__checkbox-privacy--error");
    }

    if (error === true) {
        formMsg.classList.add("form__text-subtitle--error");
        formMsg.innerHTML = "Sorry! Something went wrong. Please check and enter all fields below.";
    } else {
        formMsg.classList.remove("form__text-subtitle--error");
        formMsg.innerHTML = "Register here to secure your access to all of the content in our Graduate Showcase:";
        $errorResponse.css("display", "none");
        mailChimpSubmit.click();
    }

});
}

function validateEmail(email) {
    var re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/gim;
    if (email == "" || !re.test(email)) {
        return false;
    } else {
        return true;
    }
}
