const directory = document.querySelector(".js-directory");

if (directory) {
    const directoryColumns = document.querySelectorAll(".js-directory-col"),
        directoryTrailer = document.querySelector(".js-directory-trailor");
    let colName, activeMobCol;

    if (directoryTrailer) {
        directoryTrailer.pause();
    }

    imagesLoaded(directoryColumns, function (instance) {
        directoryColumns.forEach((directoryColumn) => {
            directoryColumn.style.opacity = 1;
        });
    });

    directoryColumns.forEach((directoryColumn) => {
        directoryColumn.addEventListener("click", function () {
            colName = directoryColumn.dataset.col;
            //console.log(colName);

            // set column states
            directoryColumns.forEach((el) => {
                el.classList.remove("directory__column--active");
            });
            directoryColumn.classList.add("directory__column--active");

            // set column widths
            directory.classList.remove(
                "directory--active-work",
                "directory--active-graduates",
                "directory--active-trailer",
                "directory--active-intro"
            );
            directory.classList.add("directory--active-" + colName);

            // trailer
            if (directoryTrailer) {
                if (colName == "trailer") {
                    directoryTrailer.play();
                } else {
                    directoryTrailer.pause();
                }
            }
        });
    });


    const directoryBtns = document.querySelectorAll(".js-mob-directory-btn");

    directoryBtns.forEach((directoryBtn) => {
        directoryBtn.addEventListener("click", function () {
            colName = directoryBtn.dataset.col;
            //console.log(colName);

             // set column states
            directoryColumns.forEach((el) => {
                el.classList.remove("directory__column--active");
            });
            activeMobCol = document.querySelector(".directory__column--" + colName);
            activeMobCol.classList.add("directory__column--active");

            // set column widths
            directory.classList.remove(
                "directory--active-work",
                "directory--active-graduates",
                "directory--active-trailer",
                "directory--active-intro"
            );
            directory.classList.add("directory--active-" + colName);

            // trailer
            if (directoryTrailer) {
                if (colName == "trailer") {
                    directoryTrailer.play();
                } else {
                    directoryTrailer.pause();
                }
            }

        });
    });

    const  directoryCloseBtns = document.querySelectorAll(".js-mob-directory-close");

    directoryCloseBtns.forEach((directoryCloseBtn) => {
        directoryCloseBtn.addEventListener("click", function (e) {

            e.stopPropagation();

            directoryColumns.forEach((el) => {
                el.classList.remove("directory__column--active");
            });

            directory.classList.remove(
                "directory--active-work",
                "directory--active-graduates",
                "directory--active-trailer",
                "directory--active-intro"
            );

            if (directoryTrailer) {
                directoryTrailer.pause();
            }

        });
    });



    
    const alphsBtns = document.querySelectorAll(".js-alpha-button"),
            gradColumn = document.querySelector(".js-graduates-inner");

    let selectedLetter, scrollPoint;

    alphsBtns.forEach((alphsBtn) => {
        alphsBtn.addEventListener("click", function () {
            selectedLetter = alphsBtn.dataset.letter;
            scrollPoint = document.querySelector(".directory-graduate__scroll-point--" + selectedLetter);

            gsap.to(gradColumn, {
                scrollTo: {
                    duration: 0.6,
                    y: scrollPoint,
                    offsetY: 20,
                    ease: Power4.easeInOut
                }
            });

        });
    });
}
