// Fade in HTML5 Videos

const homeAssociatesFlickity = document.querySelectorAll(".js-home-associates-carousel");

    homeAssociatesFlickity.forEach((slideshow) => {
       // console.log(heroFlickity);
        const flkty = new Flickity( slideshow, {
            wrapAround: true,	 
            autoPlay: false,
            pageDots: false,
            prevNextButtons: false,
            setGallerySize: true,
            cellSelector: '.home-associates__carousel-slide',
            cellAlign: 'center',
            //lazyLoad: 3,
            imagesLoaded:true
        });

        flkty.on( 'staticClick', function( event, pointer, cellElement, cellIndex ) {
            if ( !cellElement ) {
                return;
            }
            flkty.select( cellIndex)
        });

        const prevSlide = slideshow.querySelector(".js-prev"),
        nextSlide = slideshow.querySelector(".js-next");

        prevSlide.addEventListener("click", function (e) {
            flkty.previous();
        });

        nextSlide.addEventListener("click", function (e) {
            flkty.next();
        });


    });
