// Fade in HTML5 Videos

const heroFlickity = document.querySelectorAll(".js-hero-flickity");

    heroFlickity.forEach((slideshow) => {
       // console.log(heroFlickity);
        const flkty = new Flickity( slideshow, {
            wrapAround: true,	 
            autoPlay: 3000,
            pauseAutoPlayOnHover:false,
            pageDots: false,
            prevNextButtons: false,
            setGallerySize: false,
            cellSelector: '.home-hero__image',
            cellAlign: 'left',
            //lazyLoad: 3,
            imagesLoaded:true,
            fade:true,
            friction: 0.8,
            draggable: false
        });
        
    });
