/* ==========================================================================
  Ajax
	-----------------------------------------------
	- open class for elements = .js-ajax-open
	- target ajax item(s) = '#ajaxTarget'
	- scroll on .ajax (overflow: hidden)
	- call loadIn on #ajaxContainer
	
	- 	$(‘#ajaxContainer’).rhLoadIn({
       		ajax:true
		})
    
    - 	$('.parallax').rhParallax({
			parallaxRatio:1.4,
			ajax:true,
			selector:'#ajaxContainer'
		});


  ========================================================================== */

var $ajaxOverlay = $("#ajaxOverlay"),
    $closeAjaxButton = $("#closeAjaxButton"),
    $ajaxDestination = $("#ajaxDestination"),
    ajaxOpen = false,
    originalHref,
    ajaxHref,
    $loader = $("#loader");

//ajax for cards
$(document).on("click", ".js-ajax-open", function (e) {
    e.preventDefault();

    $loader.show();

    ajaxHref = $(this).attr("href");
    var type = $(this).data('type');

    if (type == 'grad') {
        $ajaxOverlay.addClass('grad');
    } else {
        $ajaxOverlay.removeClass('grad');
    }

    if (ajaxOpen == false) {
        ajaxOpen = true;

        originalHref = window.location.href;

        $body.addClass("stop-scrolling");

        TweenMax.to($ajaxOverlay, 0.4, {
            autoAlpha: 1,
            top: "0",
            ease: Power4.easeInOut,
            onComplete: openAjax,
            onCompleteParams: [ajaxHref]
        });

        TweenMax.to($closeAjaxButton, 0.3, {
            autoAlpha: 1,
            ease: Power4.easeInOut,
            delay: 0.5
        });

    }

    history.pushState({}, "", ajaxHref);
});

//close ajax
$closeAjaxButton.on("click", function (e) {
    e.preventDefault();

    ajaxOpen = false;

    $body.removeClass("stop-scrolling");

    TweenMax.to($ajaxOverlay, 0.4, {
        autoAlpha: 0,
        top: "100%",
        ease: Power4.easeInOut,
        onComplete: emptyAjax
    });

    TweenMax.to($closeAjaxButton, 0.4, { autoAlpha: 0 });
    history.pushState({}, "", originalHref);

    $ajaxOverlay.removeClass('grad');
});

//history pop state
window.onpopstate = function () {
    $body.removeClass("stop-scrolling");

    if (ajaxOpen == true) {
        ajaxOpen = false;

        TweenMax.to($ajaxOverlay, 0.4, {
            autoAlpha: 0,
            top: "100%",
            ease: Power4.easeInOut,
            onComplete: emptyAjax
        });
    
        TweenMax.to($closeAjaxButton, 0.4, { autoAlpha: 0 });

        history.pushState({}, "", originalHref);
    }
};

function openAjax(ajaxHref) {
    $ajaxDestination.load(ajaxHref + " #ajaxSource", function () {
        $loader.fadeOut();

        $ajaxDestination.imagesLoaded(function () {
            //refire any functions here

            var $videos = $ajaxDestination.find('.inline-video');

            $videos.each(function(index, value) {
                var $this = $(this);
                $this.addClass('inline-video--ready');
                $this.trigger('play')
            });
            
            // videoElems = document.getElementsByClassName("inline-video");
            // if (videoElems.length > 0) {
            //     for (let i = 0; i < videoElems.length; i++) {
            //         this.classList.add("inline-video--ready");
            
            //         videoElems[i].addEventListener('pause', function() {
            //             videoElems[i].play();
            //         });
            //     }
            // }

            videoBtns = document.querySelectorAll(".js-video-play"),
            videoOverlay = document.querySelector(".js-video-overlay"),
            videoClose = document.querySelector(".js-video-close"),
            vimeoEmbed = document.querySelector(".js-vimeo-embed"),
            triggerBtns = document.querySelectorAll(".js-warning-btn"),
            filmWrapper = document.querySelector(".js-film-wrapper"),
            warningOverlay = document.querySelector(".js-warning-overlay"),
            warningClose = document.querySelector(".js-warning-close"),
            warningPlay = document.querySelector(".js-warning-play"),
            warningTitle = document.querySelector(".js-warning-title"),
            firstVimeoPlay = true;
            if (videoBtns.length > 0) {
                vimeoPlayers();
            }

            flyoutBtns = document.querySelectorAll(".js-open-flyout"),
            flyoutCloseBtns = document.querySelectorAll(".js-close-flyout"),
            flyoutInner = document.querySelector(".js-flyout-inner"),
            flyoutImageEl = document.querySelector(".js-flyout-img"),
            flyoutTitleEl = document.querySelector(".js-flyout-title"),
            flyoutContentEl = document.querySelector(".js-flyout-content-destination"),
            flyout = document.querySelector(".js-flyout"),
            flyoutImage,
            flyoutTitle,
            flyoutContent;

            if (flyoutBtns.length > 0) {
                contentFlyouts();
            }


        });
    });
}

function emptyAjax() {
    $ajaxDestination.empty();
}
