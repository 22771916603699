

const stickyHomeElem = document.querySelector(".js-home-sticky"),
    stickyHomeWelcome = document.querySelector(".js-home-welcome");
let stickyHomeScrollTop = 0, stickyHomePageHeight, stickyHomeScrollOffset = 0, stickyWelcomeHeight = 0;

    // Detect css transform
    let cssTransform = (function(){
        let prefixes = 'transform webkitTransform mozTransform oTransform msTransform'.split(' '), 
            cssTransform, 
            i = 0
            while( cssTransform === undefined ){ 
                cssTransform = document.createElement('div').style[prefixes[i]] != undefined ? prefixes[i] : undefined
                i++
            }
            return cssTransform;
        
    })() 

if (stickyHomeElem) {



    stickyWelcomeHeight = stickyHomeWelcome.offsetHeight; 
    stickyHomeScroll();

    imagesLoaded(body, function (instance) {
        stickyWelcomeHeight = stickyHomeWelcome.offsetHeight; 
        
    });

    $(window).resize(function () {
        stickyWelcomeHeight = stickyHomeWelcome.offsetHeight; 
        stickyHomeScroll();
    });


   
    window.addEventListener("scroll", stickyHomeScroll);

    // if ($(window).width() >= 900) {
    //     $(window).on("scroll", stickyHomeScroll);
    // } else {
    //     $(window).off("scroll", stickyHomeScroll);
    // }


}


function stickyHomeScroll() {
    if ($(window).width() >= 900) {
       
        stickyHomeScrollTop = window.scrollY;

        stickyHomeScrollOffset = stickyHomeScrollTop - stickyWelcomeHeight;

       // console.log(stickyHomeScrollOffset + ' / ' + stickyWelcomeHeight);

        if (stickyHomeScrollOffset > 0) {
            stickyHomeElem.style.bottom = stickyHomeScrollOffset + "px";
            //setTop(stickyHomeElem, -stickyHomeScrollOffset); 
        } else {
            stickyHomeElem.style.bottom = "0px";
            //setTop(stickyHomeElem, 0);
        }

    }
}


function setTop(el, t){
    if (cssTransform)
        el.style[cssTransform] = "translate3d(0, "+ t +"px,0)"
    else
        el.style["top"] = t
}



