var $contactForm = $(".js-register-form"),
    $email = $(".js-register-email"),
    $errorResponses = $(".js-error-msg"),
    $submitButton = $(".js-register-submit"),
    contactFormData,
    submittedOnce = false;

function validateContactForm() {
    var errors = [],
        errorMessage = "";

    //Remove error classes and remove error message
    if ($email.hasClass("error-highlight")) {
        $email.removeClass("error-highlight");
    }

    $errorResponses.empty();

    //Get vals
    var email = $email.val();

    //VALIDATE
    if (email === "") {
        errors.push("Please enter your email.");
        $email.addClass("error-highlight");
    } else {
        if (email.length > 100) {
            errors.push("Email is too long.");
            $email.addClass("error-highlight");
        } else {
            if (validateEmail(email) === false) {
                errors.push("Please enter a valid email address.");
                $email.addClass("error-highlight");
            }
        }
    }

    //RESPOND
    var count = errors.length;
    if (count > 0) {
        for (var i = 0; i < count; i++) {
            errorMessage += errors[i];
            errorMessage += "<br />";
        }
        $errorResponses.html(errorMessage);

        return false;
    } else {
        return true;
    }
}

$contactForm.find(".js-contact-form-field").on("change", function () {
    if (submittedOnce === true) {
        validateContactForm();
    }
});

$submitButton.on("click", function (e) {
    e.preventDefault();

    submittedOnce = true;
    
    if (validateContactForm() === true) {  
        $contactForm.submit();
    }
});

function validateEmail(email) {
    var re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/gim;
    if (email == "" || !re.test(email)) {
        return false;
    } else {
        return true;
    }
}
