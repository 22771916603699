
    // this is jquery and I don't have time to rewrite it right now! Sorry 

	$('.js-ticker-mobile').each(function () {

		var $this = $(this);
		var $thisInner = $this.find('.js-ticker-mobile-inner');
        var tickerWidth = $thisInner.outerWidth() + 30;
        var direction = $this.data('dir');

        if (!direction) {
            direction = 'rtl';
        }

        $thisInner.children().clone().appendTo($thisInner);

		var time = $this.data('time');
		
		rhTickerMoveMob($thisInner,tickerWidth,time,direction);

    });

   function rhTickerMoveMob($element, tickerWidth, time, direction) {
       
        if (direction == 'rtl') {
            gsap.fromTo($element, {x:0}, {duration: time, x: -tickerWidth,ease:Linear.easeNone, onComplete: rhTickerMoveMob, onCompleteParams:[$element, tickerWidth, time, direction]});
        } else if (direction == 'ltr') {
            gsap.fromTo($element, {x:-tickerWidth}, {duration: time, x:0,ease:Linear.easeNone, onComplete: rhTickerMoveMob, onCompleteParams:[$element, tickerWidth, time, direction]});
        } 
        

	}



