const menuButton = document.querySelector(".js-menu-button"),
    menuOverlay = document.querySelector(".js-menu-overlay");

let menuActive = false;

menuButton.addEventListener("click", function (e) {
    menuButton.classList.add("is-working");

    menuActive = !menuActive;

    if (searchActive) {
        searchButton.classList.remove("search-button--active");
        TweenMax.to(searchOverlay, 0.3, {autoAlpha: 0});
        searchActive = false;
    }

    if (menuActive) {
        body.classList.add("stop-scrolling");
        menuButton.classList.add("menu-button--active");
        TweenMax.to(menuOverlay, 0.3, {autoAlpha: 1});

        //openMenuAnimations();
    } else {
        body.classList.remove("stop-scrolling");
        menuButton.classList.remove("menu-button--active");
        TweenMax.to(menuOverlay, 0.3, {autoAlpha: 0});
        //closeMenuAnimations();
    }

    setTimeout(function () {
        menuButton.classList.remove("is-working");
    }, 200);
});
