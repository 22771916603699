
const heroEl = document.querySelector('.js-hero-height');

if (heroEl) {
    heroSize();
}

function heroSize() {
    heroEl.style.height = height + "px";
}
