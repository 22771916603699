const searchButton = document.querySelector(".js-search-button"),
    searchOverlay = document.querySelector(".js-search-overlay");

let searchActive = false;

searchButton.addEventListener("click", function (e) {
    searchButton.classList.add("is-working");

    searchActive = !searchActive; 

    if (menuActive) {
        menuButton.classList.remove("menu-button--active");
        TweenMax.to(menuOverlay, 0.3, {autoAlpha: 0});
        menuActive = false;
    }

    if (searchActive) {
        body.classList.add("stop-scrolling");
        searchButton.classList.add("search-button--active");
        TweenMax.to(searchOverlay, 0.3, {autoAlpha: 1});
    } else {
        body.classList.remove("stop-scrolling");
        searchButton.classList.remove("search-button--active");
        TweenMax.to(searchOverlay, 0.3, {autoAlpha: 0});
    }

    setTimeout(function () {
        searchButton.classList.remove("is-working");
    }, 200);
});


var $searchResults = $('.js-search-results');


$(document).on( 'submit', '#js-search-form', function() {
    var $form = $(this);
    var $input = $form.find('input[name="s"]');
    var ajaxUrl = $form.attr('action');
    var query = $input.val(); 
    
    $.ajax({
         type : 'post',
         url : ajaxUrl,
         data : {
              action : 'load_search_results',
              query : query
         },
         beforeSend: function() { 
              $searchResults.addClass('search-overlay--results-loading').empty();
              $searchResults.addClass('search-overlay--results-loaded');
         },
         success : function( response ) {
              $searchResults.removeClass('search-overlay--results-loading');
              $searchResults.html( response );
              $searchResults.val('Search again');
         }
    });
    
    return false;
})
