// Codekit -->
// @codekit-prepend "global/vars.js"

// @codekit-prepend "components/rhparallax.js"
// @codekit-prepend "components/loadIn.js"
// @codekit-prepend "components/nav.js"
// @codekit-prepend "components/video.js"
// @codekit-prepend "components/ajax.js"
// @codekit-prepend "components/directory.js"
// @codekit-prepend "components/sticky.js"
// @codekit-prepend "components/ticker.js"
// @codekit-prepend "components/mobile-ticker.js
// @codekit-prepend "components/hero-flickity.js"
// @codekit-prepend "components/homepage-sticky.js"
// @codekit-prepend "components/associate-flickity.js"
// @codekit-prepend "components/events-flickity.js
// @codekit-prepend "components/flyout.js
// @codekit-prepend "components/hero-size.js
// @codekit-prepend "components/register.js
// @codekit-prepend "components/search.js
// @codekit-prepend "components/countdown.js"
// @codekit-prepend "components/form.js"
// @codekit-prepend "components/programme.js"

// @codekit-append "global/resize.js"

//on ready
$(document).ready(function () {
    // $body.rhLoadIn();

    // $(".parallax").rhParallax({
    //     parallaxRatio: 1.4
    // });

    // //images loaded
    // $(".bgImgLoad").imagesLoaded({ background: ".bgImgLoad" }, function () {
    //     $(".bgImgLoad").animate({ opacity: "1" }, 300);
    // });

    // $(".imgLoad").imagesLoaded(function () {
    //     $(".imgLoad").animate({ opacity: "1" }, 300);
    // });
}); // end doc ready

//This pauses videos on page refresh
//Ensures no flash of white
// $(window).on("beforeunload", function () {
//     //console.log('PAUSE VIDEO');
//     $(".vidUnload").each(function () {
//         $(this).get(0).pause();
//     });
// });