const openProgrammeBtns = document.querySelectorAll(".js-programme-open"),
closeProgrammeBtns = document.querySelectorAll(".js-programme-close");

openProgrammeBtns.forEach((btn) => {
    btn.addEventListener("click", function (e) {
        e.preventDefault();
        btn.nextElementSibling.classList.add('active');
    });
});

closeProgrammeBtns.forEach((btn) => {
    btn.addEventListener("click", function (e) {
        e.preventDefault();
        btn.closest(".programme-overlay").classList.remove('active');
    });
});