
    // this is jquery and I don't have time to rewrite it right now! Sorry 

	$('.js-ticker').each(function () {

		var $this = $(this);
		var $thisInner = $this.find('.js-ticker-inner');
		var tickerHeight = $thisInner.outerHeight() + 20;

        $thisInner.children().clone().appendTo($thisInner);

		var time = $this.data('time');
		
		rhTickerMove($thisInner,tickerHeight,time);

    });

   function rhTickerMove($element, tickerHeight, time) {
       
        gsap.fromTo($element, {y:0}, {duration: time, y: -tickerHeight,ease:Linear.easeNone, onComplete: rhTickerMove, onCompleteParams:[$element, tickerHeight, time]});

	}



