
  //countdown
  const countdown = document.querySelector(".js-countdown"); 
  let countdownDateTime, countDownDate, countdownFirstLoad = true, distance, now;
  const countdownWrapper = document.querySelector('.js-countdown-wrapper');

    if (countdown) {
        countdownDateTime = countdown.dataset.date; 
        countDownDate = new Date(countdownDateTime).getTime(); 
      
        now = new Date().getTime(); 
        distance = countDownDate - now; 

        if (distance <= 0) {
            gsap.set(countdownWrapper, {
                autoAlpha: 0,
                display: "none"
            });
        } else {
        countdownTimer();
        }
    } 

  function countdownTimer() {
    setInterval(function() {

        now = new Date().getTime(); 
        distance = countDownDate - now;
        if (distance <= 0) {
            gsap.to(countdownWrapper, 3, {
                autoAlpha: 0,
                display: "none"
            });
        } else {
            
            if (countdownFirstLoad) {
                gsap.to(countdown, 3, {autoAlpha: 1});
                countdownFirstLoad = false;
            }
           
        }

        // Time calculations for days, hours, minutes and seconds
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        //zero pad
        Number.prototype.zeroPad = function() {
        return ('0'+this).slice(-2);
        };

        // Display the result
        if (days > -1) {
            document.querySelector(".countdown__el-text--day").textContent = days.zeroPad();
        }       
        if (hours > -1) {
            document.querySelector(".countdown__el-text--hour").textContent = hours.zeroPad();
        }
        if (minutes > -1) {
            document.querySelector(".countdown__el-text--min").textContent =  minutes.zeroPad();
        }
        if (seconds > -1) {
            document.querySelector(".countdown__el-text--sec").textContent =  seconds.zeroPad();
        }       

    }, 1000);

}

